/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

import { decapitalize } from 'app/utils/helpers/helpers';

@Pipe({
	name: 'decapitalize',
	standalone: false,
})
export class DecapitalizePipe implements PipeTransform {
	public transform(value: string): string {
		return decapitalize(value);
	}
}
