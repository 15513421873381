/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { HasOne, ModelConfig } from 'ngx-hal';
import { HalDatastoreModel } from '../services/datastore/models/hal-datastore-model.model';
import { Accessory } from './accessory.hal.model';

@ModelConfig({
	type: 'AccessoryKitAccessory',
})
export class AccessoryKitAccessory extends HalDatastoreModel {
	public static modelType = 'AccessoryKitAccessory';

	@HasOne({
		propertyClass: Accessory,
	})
	public accessory: Accessory;

	@HasOne({
		propertyClass: Accessory,
	})
	public accessoryKit: Accessory;
}
