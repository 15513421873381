/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import {
	Attribute as FormAttribute,
	BelongsTo as FormBelongsTo,
	HasMany as FormHasMany,
} from 'ngx-form-object';
import { Attribute, HasMany, HasOne, ModelConfig, ModelEndpoints } from 'ngx-hal';
import { HighLevelCategoryEnum } from '../enums/high-level-category.enum';
import { Language } from '../enums/language.enum';
import { PowerUnit } from '../enums/power-unit.enum';
import { PressureUnit } from '../enums/pressure-unit.enum';
import { TemperatureUnit } from '../enums/temperature-unit.enum';
import { IDeviceOptionLimits } from '../interfaces/device-config.interface';
import { compareArrays } from '../utils/helpers/compare-arrays/compare-arrays';
import { isCompactDevice } from '../utils/helpers/is-compact-device/is-compact-device.helper';
import { Accessory } from './accessory.hal.model';
import { AttributeConfig } from './attribute-config/attribute-config.model';
import { Category } from './category.model';
import { CookingMethod } from './cooking-method.model';
import { ProductBrand } from './product-brand.model';
import { ProductRange } from './product-range.model';
import { Product } from './product.model';

@ModelConfig({
	type: 'Device',
})
export class Device extends Product {
	public static modelType = 'Device';
	public displayNameTranslationKey = 'Device';
	public readonly isDevice = true;
	public readonly isAccessory = false;

	@Attribute()
	@FormAttribute()
	public range: string;

	@Attribute()
	@FormAttribute()
	public codename: string;

	@Attribute()
	public isConnectable: boolean;

	@Attribute({
		transformBeforeSave: (time) => {
			if (!time) {
				return undefined;
			}
			return time.min !== null && time.max !== null ? time : undefined;
		},
	})
	@FormAttribute()
	public time: Pick<IDeviceOptionLimits, 'max' | 'min'>;

	@Attribute({
		transformBeforeSave: (temperature) => {
			if (!temperature) {
				return undefined;
			}
			return temperature.min !== null && temperature.max !== null ? temperature : undefined;
		},
	})
	@FormAttribute()
	public temperature: Pick<
		IDeviceOptionLimits<TemperatureUnit>,
		'interval' | 'max' | 'min' | 'unit'
	>;

	@Attribute({
		transformBeforeSave: (pressure) => {
			if (!pressure) {
				return undefined;
			}
			return pressure.min !== null && pressure.max !== null ? pressure : undefined;
		},
	})
	@FormAttribute()
	public pressure: Pick<IDeviceOptionLimits<PressureUnit>, 'interval' | 'max' | 'min' | 'unit'>;

	// This property doesn't exist on backend model at the moment;
	// it's used for supporting some cooking methods for Rice cooker connected
	@Attribute()
	public power: IDeviceOptionLimits<PowerUnit>;

	@HasOne({
		propertyClass: 'AttributeConfig',
	})
	public attributeConfig: AttributeConfig;

	@HasOne({
		propertyClass: ProductRange,
		includeInPayload: true,
	})
	@FormBelongsTo()
	public productRange: ProductRange;

	@HasMany({
		itemsType: 'Accessory',
	})
	@FormHasMany({
		isChanged: (initial: Array<Accessory>, current: Array<Accessory>) =>
			!compareArrays(initial, current, {
				ignoreOrder: true,
				propertyFn: (item: Accessory) => item.id,
			}),
	})
	public accessories: Array<Accessory>;

	@HasMany({
		itemsType: 'Accessory',
	})
	@FormHasMany({
		isChanged: (initial: Array<Accessory>, current: Array<Accessory>) =>
			!compareArrays(initial, current, {
				ignoreOrder: true,
				propertyFn: (item: Accessory) => item.id,
			}),
	})
	public preselectedAccessories: Array<Accessory>;

	@HasMany({
		itemsType: ProductBrand,
	})
	@FormHasMany({
		isChanged: (initial: Array<ProductBrand>, current: Array<ProductBrand>) =>
			!compareArrays(initial, current, {
				ignoreOrder: true,
				propertyFn: (item: ProductBrand) => `${item.country.code}-${item.brand.id}`,
			}),
	})
	public productBrands: Array<ProductBrand>;

	@HasMany({
		itemsType: CookingMethod,
	})
	public cookingMethods: Array<CookingMethod>;

	public get isCompact(): boolean {
		return isCompactDevice(this.id);
	}

	public get displayName(): string {
		return this.deviceCtnLabel ? `${this.name} (${this.deviceCtnLabel})` : this.name;
	}

	public productGroupLabel(language: Language): string {
		const groupCategory: Category = this.categories.find(
			(category: Category) => category.parent?.parent?.slug === HighLevelCategoryEnum.DEVICE_GROUP,
		);
		if (!groupCategory) {
			return '';
		}

		return groupCategory.getDisplayName(language);
	}

	public get modelEndpoints(): ModelEndpoints {
		return {
			collectionEndpoint: this.datastore.rootApi.deviceCollectionEndpoint,
			singleResourceEndpoint: this.datastore.rootApi.singleDeviceEndpoint,
		};
	}
}
