/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Component, Inject, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { IDialogData } from 'app/components/popup-dialog/dialog-options.interface';
import { PopupDialogComponent } from 'app/components/popup-dialog/popup-dialog.component';

@Component({
	selector: 'confirmation-dialog',
	templateUrl: 'confirmation-dialog.component.html',
	styleUrls: ['confirmation-dialog.component.scss'],
	standalone: false,
})
export class ConfirmationDialogComponent extends PopupDialogComponent {
	public constructor(
		public dialogRef: MatDialogRef<PopupDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IDialogData,
		protected renderer: Renderer2,
	) {
		super(dialogRef, data, renderer);
	}

	public get isSuccessButtonEnabled(): boolean {
		return true;
	}
}
