/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export enum IngredientUnit {
	BAG = 'BAG',
	BRANCH = 'BRANCH',
	BUNCH = 'BUNCH',
	CAN = 'CAN',
	CENTILITER = 'CENTILITER',
	CLOVE = 'CLOVE',
	CUP = 'METRIC_CUP',
	US_CUP = 'US_CUP',
	DASH = 'DASH',
	DECIGRAM = 'DECIGRAM',
	DECILITER = 'DECILITER',
	DROP = 'DROP',
	GRAM = 'GRAM',
	KILOGRAM = 'KILOGRAM',
	KCAL = 'KCAL',
	LITER = 'LITER',
	MILLIGRAM = 'MILLIGRAM',
	MILLILITER = 'MILLILITER',
	MICROGRAM = 'MICROGRAM',
	OUNCE = 'OUNCE',
	PERCENT = 'PERCENT',
	PIECE = 'PIECE',
	PINCH = 'PINCH',
	POUND = 'POUND',
	SACHET = 'SACHET',
	SCOOP = 'SCOOP',
	SLICE = 'SLICE',
	SHOT = 'SHOT',
	US_STICK = 'US_STICK',
	TABLESPOON = 'TABLESPOON',
	TEASPOON = 'TEASPOON',
	US_FLUID_OUNCE = 'US_FLUID_OUNCE',
}

export const ingredientUnitData = {
	[IngredientUnit.BAG]: {
		id: IngredientUnit.BAG,
		label: 'bag',
		translationKey: 'ingredientUnit.bag',
		usePlural: false,
	},
	[IngredientUnit.BRANCH]: {
		id: IngredientUnit.BRANCH,
		label: 'branch',
		translationKey: 'ingredientUnit.branch',
		usePlural: false,
	},
	[IngredientUnit.BUNCH]: {
		id: IngredientUnit.BUNCH,
		label: 'bunch',
		translationKey: 'ingredientUnit.bunch',
		usePlural: false,
	},
	[IngredientUnit.CAN]: {
		id: IngredientUnit.CAN,
		label: 'can',
		translationKey: 'ingredientUnit.can',
		usePlural: false,
	},
	[IngredientUnit.CENTILITER]: {
		id: IngredientUnit.CENTILITER,
		label: 'centiliter',
		translationKey: 'ingredientUnit.centiliter',
		usePlural: false,
	},
	[IngredientUnit.CLOVE]: {
		id: IngredientUnit.CLOVE,
		label: 'clove',
		translationKey: 'ingredientUnit.clove',
		usePlural: false,
	},
	[IngredientUnit.CUP]: {
		id: IngredientUnit.CUP,
		label: 'cup',
		translationKey: 'ingredientUnit.cup',
		usePlural: false,
	},
	[IngredientUnit.US_CUP]: {
		id: IngredientUnit.US_CUP,
		label: 'cup',
		translationKey: 'ingredientUnit.cup',
		usePlural: false,
	},
	[IngredientUnit.DASH]: {
		id: IngredientUnit.DASH,
		label: 'dash',
		translationKey: 'ingredientUnit.dash',
		usePlural: false,
	},
	[IngredientUnit.DECIGRAM]: {
		id: IngredientUnit.DECIGRAM,
		label: 'decigram',
		translationKey: 'ingredientUnit.decigram',
		usePlural: false,
	},
	[IngredientUnit.DECILITER]: {
		id: IngredientUnit.DECILITER,
		label: 'deciliter',
		translationKey: 'ingredientUnit.deciliter',
		usePlural: false,
	},
	[IngredientUnit.KCAL]: {
		id: IngredientUnit.KCAL,
		label: 'kcal',
		translationKey: 'ingredientUnit.nutrientUnits.kcal',
		usePlural: false,
	},
	[IngredientUnit.DROP]: {
		id: IngredientUnit.DROP,
		label: 'drop',
		translationKey: 'ingredientUnit.drop',
		usePlural: false,
	},
	[IngredientUnit.GRAM]: {
		id: IngredientUnit.GRAM,
		label: 'gram',
		translationKey: 'ingredientUnit.gram',
		usePlural: false,
	},
	[IngredientUnit.KILOGRAM]: {
		id: IngredientUnit.KILOGRAM,
		label: 'kilogram',
		translationKey: 'ingredientUnit.kilogram',
		usePlural: false,
	},
	[IngredientUnit.LITER]: {
		id: IngredientUnit.LITER,
		label: 'liter',
		translationKey: 'ingredientUnit.liter',
		usePlural: false,
	},
	[IngredientUnit.MILLIGRAM]: {
		id: IngredientUnit.MILLIGRAM,
		label: 'milligram',
		translationKey: 'ingredientUnit.milligram',
		usePlural: false,
	},
	[IngredientUnit.MILLILITER]: {
		id: IngredientUnit.MILLILITER,
		label: 'milliliter',
		translationKey: 'ingredientUnit.milliliter',
		usePlural: false,
	},
	[IngredientUnit.MICROGRAM]: {
		id: IngredientUnit.MICROGRAM,
		label: 'microgram',
		translationKey: 'ingredientUnit.microgram',
		usePlural: false,
	},
	[IngredientUnit.OUNCE]: {
		id: IngredientUnit.OUNCE,
		label: 'ounce',
		translationKey: 'ingredientUnit.ounce',
		usePlural: true,
	},
	[IngredientUnit.SACHET]: {
		id: IngredientUnit.SACHET,
		label: 'sachet',
		translationKey: 'ingredientUnit.sachet',
		usePlural: false,
	},
	[IngredientUnit.SCOOP]: {
		id: IngredientUnit.SCOOP,
		label: 'scoop',
		translationKey: 'ingredientUnit.scoop',
		usePlural: false,
	},
	[IngredientUnit.PERCENT]: {
		id: IngredientUnit.PERCENT,
		label: '%',
		translationKey: 'ingredientUnit.percent',
		usePlural: false,
	},
	[IngredientUnit.PIECE]: {
		id: IngredientUnit.PIECE,
		label: 'piece',
		translationKey: 'ingredientUnit.piece',
		usePlural: false,
	},
	[IngredientUnit.PINCH]: {
		id: IngredientUnit.PINCH,
		label: 'pinch',
		translationKey: 'ingredientUnit.pinch',
		usePlural: false,
	},
	[IngredientUnit.POUND]: {
		id: IngredientUnit.POUND,
		label: 'pound',
		translationKey: 'ingredientUnit.pound',
		usePlural: false,
	},
	[IngredientUnit.SLICE]: {
		id: IngredientUnit.SLICE,
		label: 'slice',
		translationKey: 'ingredientUnit.slice',
		usePlural: false,
	},
	[IngredientUnit.SHOT]: {
		id: IngredientUnit.SHOT,
		label: 'shot',
		translationKey: 'ingredientUnit.shot',
		usePlural: false,
	},
	[IngredientUnit.US_STICK]: {
		id: IngredientUnit.US_STICK,
		label: 'stick',
		translationKey: 'ingredientUnit.stick',
		usePlural: false,
	},
	[IngredientUnit.TABLESPOON]: {
		id: IngredientUnit.TABLESPOON,
		label: 'tablespoons',
		translationKey: 'ingredientUnit.tablespoon',
		usePlural: true,
	},
	[IngredientUnit.TEASPOON]: {
		id: IngredientUnit.TEASPOON,
		label: 'teaspoons',
		translationKey: 'ingredientUnit.teaspoon',
		usePlural: true,
	},
	[IngredientUnit.US_FLUID_OUNCE]: {
		id: IngredientUnit.US_FLUID_OUNCE,
		label: 'fluid ounce',
		translationKey: 'ingredientUnit.fluidOunce',
		usePlural: false,
	},
};

export const nonselectableUnits = [
	IngredientUnit.BAG,
	IngredientUnit.CAN,
	IngredientUnit.CLOVE,
	IngredientUnit.KCAL,
	IngredientUnit.MILLIGRAM,
	IngredientUnit.PERCENT,
	IngredientUnit.SACHET,
];

export const hiddenUnits = [];

export const convertibleUnits = [
	IngredientUnit.BAG,
	IngredientUnit.BRANCH,
	IngredientUnit.BUNCH,
	IngredientUnit.CAN,
	IngredientUnit.CENTILITER,
	IngredientUnit.CLOVE,
	IngredientUnit.CUP,
	IngredientUnit.DASH,
	IngredientUnit.DECIGRAM,
	IngredientUnit.DECILITER,
	IngredientUnit.DROP,
	IngredientUnit.MILLILITER,
	IngredientUnit.PIECE,
	IngredientUnit.PINCH,
	IngredientUnit.SACHET,
	IngredientUnit.SCOOP,
	IngredientUnit.SHOT,
	IngredientUnit.SLICE,
	IngredientUnit.US_STICK,
	IngredientUnit.TABLESPOON,
	IngredientUnit.TEASPOON,
];

export const editableSourceAmountUnits = {
	[IngredientUnit.MILLILITER]: {
		defaultAmount: 100,
	},
};

export const defaultUnit = IngredientUnit.PIECE;

const ingredientUnitConversionDependenciesAll = {};

const ingredientUnitConversionDependenciesOverrides = {
	[IngredientUnit.LITER]: {
		requiresAtLeastOneConversion: [IngredientUnit.MILLILITER],
	},
	[IngredientUnit.DECILITER]: {
		requiresAtLeastOneConversion: [IngredientUnit.MILLILITER],
	},
	[IngredientUnit.CENTILITER]: {
		requiresAtLeastOneConversion: [IngredientUnit.MILLILITER],
	},
	[IngredientUnit.US_CUP]: {
		requiresAtLeastOneConversion: [IngredientUnit.CUP], // TODO: Specific unit conversion V2
	},
};

Object.values(IngredientUnit).forEach((unit: IngredientUnit) => {
	if (ingredientUnitConversionDependenciesOverrides[unit]) {
		ingredientUnitConversionDependenciesAll[unit] =
			ingredientUnitConversionDependenciesOverrides[unit];
	} else if (convertibleUnits.indexOf(unit as any) > -1) {
		ingredientUnitConversionDependenciesAll[unit] = {
			requiresAtLeastOneConversion: [unit],
		};
	}
});

export const ingredientUnitConversionDependencies = ingredientUnitConversionDependenciesAll;
