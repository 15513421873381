/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import {
	Attribute as FormAttribute,
	BelongsTo as FormBelongsTo,
	HasMany as FormHasMany,
} from 'ngx-form-object';
import {
	Attribute,
	HasMany,
	HasOne,
	HeaderAttribute,
	Link,
	ModelConfig,
	ModelEndpoints,
} from 'ngx-hal';
import { contentContainerCategoryData } from '../enums/content-container-category.enum';
import {
	ContentContainerContentType,
	ContentContainerType,
} from '../enums/content-container-type.enum';
import { contentTypeData } from '../enums/content-type.hal.enum';
import { Language } from '../enums/language.enum';
import { compareArrays } from '../utils/helpers/compare-arrays/compare-arrays';
import {
	transformLanguageForHalApi,
	transformLanguageForJsonApi,
} from '../utils/helpers/language-transformer/language-transformer.helper';
import { Accessory } from './accessory.hal.model';
import { Article } from './article.model';
import { Category } from './category.model';
import { Collection } from './collection.hal.model';
import { ContentContainerTranslation } from './content-container-translation.model';
import { Content } from './content.model';
import { Country } from './country.hal.model';
import { Device } from './device.hal.model';
import { Media } from './media.hal.model';
import { Profile } from './profile.hal.model';
import { Recipe } from './recipe.hal.model';

@ModelConfig({
	type: 'ContentContainer',
})
export class ContentContainer extends Content {
	public get modelEndpoints(): ModelEndpoints {
		return {
			singleResourceEndpoint: this.datastore.rootApi.singleContentContainerEndpoint,
			collectionEndpoint: this.datastore.rootApi.contentContainerCollectionEndpoint,
		};
	}

	@HasMany({
		itemsType: Category,
		includeInPayload: true,
	})
	@FormHasMany({
		isChanged: (initial: Array<Category>, current: Array<Category>) =>
			!compareArrays(initial, current, {
				ignoreOrder: true,
				propertyFn: (item: Category) => item?.slug || '',
			}),
	})
	public categories: Array<Category>;

	@Attribute()
	@FormAttribute()
	public url: string;

	@Attribute()
	@FormAttribute()
	public code: string;

	@HasOne({
		includeInPayload: true,
		propertyClass: Media,
	})
	@FormBelongsTo()
	public image: Media;

	@HasOne({
		includeInPayload: true,
		propertyClass: Media,
	})
	@FormBelongsTo()
	public video: Media;

	@HasOne({
		propertyClass: Recipe,
		includeInPayload: true,
	})
	@FormBelongsTo()
	public recipe: Recipe;

	@HasOne({
		propertyClass: Collection,
		includeInPayload: true,
	})
	@FormBelongsTo()
	public collection: Collection;

	@HasOne({
		propertyClass: Article,
		includeInPayload: true,
	})
	@FormBelongsTo()
	public article: Article;

	@HasOne({
		propertyClass: Profile,
		includeInPayload: true,
	})
	@FormBelongsTo()
	public profile: Profile;

	@HasMany({
		itemsType: ContentContainerTranslation,
		includeInPayload: true,
	})
	@FormHasMany()
	public translations: Array<ContentContainerTranslation>;

	@HasOne({
		includeInPayload: true,
		propertyClass: ContentContainerTranslation,
	})
	@FormBelongsTo()
	public defaultTranslation: ContentContainerTranslation;

	// Used in forms, mapped to actual properties before saving
	@FormBelongsTo()
	public content: Recipe | Collection | Article | null;

	@Attribute({
		externalName: 'type',
	})
	@FormAttribute()
	public contentContainerType: ContentContainerType;

	/**
	 * Fetched and assigned manually
	 */
	@FormHasMany({
		isChanged: (initial: Array<Category>, current: Array<Category>) =>
			!compareArrays(initial, current, {
				ignoreOrder: true,
				propertyFn: (item: Category) => item?.slug || '',
			}),
	})
	public deviceCategories: Array<Category>;

	@HasMany({
		itemsType: Device,
	})
	@FormHasMany({
		isChanged: (initial: Array<Device>, current: Array<Device>) =>
			!compareArrays(initial, current, {
				ignoreOrder: true,
				propertyFn: (item: Device) => item?.id || '',
			}),
	})
	public devices: Array<Device>;

	@HasMany({
		itemsType: Accessory,
	})
	@FormHasMany({
		isChanged: (initial: Array<Accessory>, current: Array<Accessory>) =>
			!compareArrays(initial, current, {
				ignoreOrder: true,
				propertyFn: (item: Accessory) => item?.id || '',
			}),
	})
	public accessories: Array<Accessory>;

	/**
	 * Fetched and assigned manually
	 */
	@FormBelongsTo()
	public contentContainerCategory: Category;

	@Attribute()
	// TODO: NUT-51722 remove usage of this attribute property,
	// and rename to initialTitle, to be used only for creation
	public title: string;

	@Attribute({
		externalName: 'description',
	})
	// Use only for creation of the ContentContainer resource
	public initialDescription: string;

	@HeaderAttribute({
		transformResponseValue: transformLanguageForJsonApi,
		transformBeforeSave: transformLanguageForHalApi,
		externalName: 'Content-Language',
	})
	// Use only for creation of the ContentContainer resource
	public initialLanguage: Language;

	@HasMany({
		itemsType: Country,
		includeInPayload: true,
		externalName: 'countries',
	})
	// Use only for creation of the ContentContainer resource
	public initialCountries: Array<Country>;

	@HasMany({
		itemsType: Category,
		includeInPayload: true,
		externalName: 'categories',
	})
	// Use only for creation of the ContentContainer resource
	public initialCategories: Array<Category>;

	public get isTypeContent(): boolean {
		return this.contentContainerType === ContentContainerType.CONTENT;
	}

	public get extendedContentContainerType(): ContentContainerType | ContentContainerContentType {
		if (!this.isTypeContent) {
			return this.contentContainerType;
		}

		let type: ContentContainerContentType;

		if (this.links.article) {
			type = ContentContainerContentType.ARTICLE;
		} else if (this.links.collection) {
			type = ContentContainerContentType.RECIPE_BOOK;
		} else if (this.links.recipe) {
			type = ContentContainerContentType.RECIPE;
		}

		return type;
	}

	// Only Admin UI users can create content containers
	public managed = true;

	public get relatedContentTypeTranslationKey(): string {
		return this.relatedContent?.displayNameTranslationKey || null;
	}

	public get relatedContentShortID(): string {
		return this.relatedContent?.shortId || null;
	}

	public get relatedContentTitle(): string {
		return this.relatedContent?.title || null;
	}

	public get relatedContentUrl(): string {
		if (this.article) {
			return `/admin/articles/${this.article.id}`;
		} else if (this.collection) {
			return `/admin/collections/${this.collection.id}`;
		} else if (this.recipe) {
			return `/admin/recipes/${this.recipe.id}`;
		}

		return null;
	}

	public get relatedContent(): Article | Collection | Recipe {
		return this.article || this.collection || this.recipe;
	}

	public get displayNameTranslationKey(): string {
		return contentTypeData[
			contentContainerCategoryData[this.contentContainerCategory.slug].contentType
		].translationKey;
	}

	@Link({
		externalName: 'products',
	})
	public deviceAndAccessoryCreationEndpoint: string;

	public get displayImage(): Media {
		return this.image || this.video;
	}
}
