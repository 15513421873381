/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'join',
	standalone: false,
})
export class JoinPipe implements PipeTransform {
	public transform(items: Array<string> = [], separator = ', '): string {
		return items.join(separator);
	}
}
