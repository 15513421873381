/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Component, Inject, OnInit, Renderer2, OnDestroy, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { KeyCode } from '../../enums/key-code.enum';

import { IDialogData } from 'app/components/popup-dialog/dialog-options.interface';

@Component({
	selector: 'popup-dialog-component',
	templateUrl: 'popup-dialog.component.html',
	styleUrls: ['popup-dialog.component.scss'],
	standalone: false,
})
export class PopupDialogComponent implements OnInit, OnDestroy {
	public constructor(
		public dialogRef: MatDialogRef<PopupDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IDialogData,
		protected renderer: Renderer2,
	) {}

	public ngOnInit(): void {
		this.disableBodyScroll();
	}

	public onCancelClick(): void {
		let canClose = true;

		if (this.data.cancelButton.callback) {
			canClose = this.data.cancelButton.callback();
		}

		if (canClose !== false) {
			this.dialogRef.close(false);
		}
	}

	public onSaveClick(): void {
		let canClose = true;

		if (this.data.saveButton.callback) {
			canClose = this.data.saveButton.callback();
		}

		if (canClose !== false) {
			this.dialogRef.close(true);
		}
	}

	public get isSuccessButtonEnabled(): boolean {
		return false;
	}

	protected get callCancelOnKeypressEnter(): boolean {
		return false;
	}

	@HostListener('document:keydown', ['$event'])
	public handleKeyboardDownEvent(event: KeyboardEvent): void {
		if (event.keyCode === KeyCode.ESCAPE) {
			this.onCancelClick();
			return;
		}

		const pressedCtrlOrCmdWithEnter: boolean = this.isMetaPlusEnterPressed(event);
		if (pressedCtrlOrCmdWithEnter && this.isSuccessButtonEnabled) {
			this.onSaveClick();
			return;
		}

		if (pressedCtrlOrCmdWithEnter && this.callCancelOnKeypressEnter) {
			this.onCancelClick();
			return;
		}
	}

	protected disableBodyScroll(): void {
		this.renderer.addClass(document.body, 'modal-opened');
	}

	protected enableBodyScroll(): void {
		this.renderer.removeClass(document.body, 'modal-opened');
	}

	private isMetaPlusEnterPressed(event: KeyboardEvent): boolean {
		return (event.ctrlKey || event.metaKey) && event.keyCode === KeyCode.ENTER;
	}

	public ngOnDestroy() {
		this.enableBodyScroll();
	}
}
