/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

import { Media } from '../../models/media.hal.model';

@Pipe({
	name: 'imageSize',
	standalone: false,
})
export class ImageSizePipe implements PipeTransform {
	private getAvailableSizes(media: object | Media): object {
		if (media instanceof Media) {
			return media.availableSizes.sizes;
		} else {
			return media['availableSizes'];
		}
	}

	public transform(media: object, size: string): string {
		if (media && size) {
			const imageSize = this.getAvailableSizes(media)[size];

			if (imageSize) {
				return imageSize;
			}
		}

		return null;
	}
}
