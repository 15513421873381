/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Component, Inject, Renderer2 } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { IDialogData } from 'app/components/popup-dialog/dialog-options.interface';
import { PopupDialogComponent } from 'app/components/popup-dialog/popup-dialog.component';

@Component({
	selector: 'comment-modal',
	templateUrl: 'comment-modal.component.html',
	styleUrls: ['comment-modal.component.scss'],
	standalone: false,
})
export class CommentModalComponent extends PopupDialogComponent {
	public commentForm: UntypedFormGroup = new UntypedFormGroup({
		comment: new UntypedFormControl(),
	});

	public constructor(
		public dialogRef: MatDialogRef<PopupDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IDialogData,
		protected renderer: Renderer2,
	) {
		super(dialogRef, data, renderer);
		this.initValidation();
	}

	public get isSuccessButtonEnabled(): boolean {
		return this.commentForm.valid;
	}

	public onSaveClick(): void {
		let canClose = true;
		const comment: string = this.commentForm.get('comment').value;

		if (this.data.saveButton.callback) {
			canClose = this.data.saveButton.callback(comment);
		}

		if (canClose !== false) {
			this.dialogRef.close({
				comment,
				shouldProceed: true,
			});
		}
	}

	private initValidation(): void {
		const validateComment = this.data.extra.validateComment;

		if (validateComment) {
			this.commentForm.controls.comment.setValidators(Validators.required);
		}
	}

	public onCancelClick(): void {
		let canClose = true;

		if (this.data.cancelButton.callback) {
			canClose = this.data.cancelButton.callback();
		}

		if (canClose !== false) {
			this.dialogRef.close({ shouldProceed: false });
		}
	}
}
