/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

import { FormStore } from 'ngx-form-object';

import { getFormByFormField } from 'app/utils/helpers/helpers';

@Pipe({
	name: 'getFormByFormField',
	standalone: false,
})
export class GetFormByFormFieldPipe implements PipeTransform {
	public transform(forms: Array<FormStore>, formFieldName: string, formFieldValue: any): FormStore {
		return getFormByFormField(forms, formFieldName, formFieldValue);
	}
}
