/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

import { isNumber } from '../../utils/helpers/helpers';
import { numberToFraction } from '../../utils/helpers/number-to-fraction/number-to-fraction.helper';

@Pipe({
	name: 'quantityBasedTranslationKey',
	standalone: false,
})
export class QuantityBasedTranslationKeyPipe implements PipeTransform {
	public transform(key: string, quantity: number, displayAsFraction = false): string {
		let translationKey: string = key;

		const displayQuantity: number = displayAsFraction
			? this.getValueAfterConvertingToFraction(quantity)
			: this.getValue(quantity);

		if (displayQuantity === 0) {
			translationKey = `${key}.empty`;
		} else if (displayQuantity === 1) {
			translationKey = `${key}.singular`;
		} else if ((displayQuantity > 0 && displayQuantity < 1) || displayQuantity > 1) {
			translationKey = `${key}.plural`;
		} else {
			console.warn(`Quantity ${displayQuantity} is not a valid number (${key})`);
		}

		return translationKey;
	}

	private getValueAfterConvertingToFraction(value: number): number {
		if (!isNumber(value)) {
			return null;
		}

		const { integer, fraction } = numberToFraction(value);

		if (!fraction) {
			return integer;
		}

		return integer + fraction.numerator / fraction.denominator;
	}

	private getValue(value: number | string): number {
		const isValueString: boolean = typeof value === 'string';
		const isValueNumber: boolean = isNumber(value);
		const isValueEmptyString: boolean = isValueString && (value as string).trim() === '';
		const isValueStringOrNumber: boolean = isValueString || isValueNumber;

		if (isValueEmptyString || !isValueStringOrNumber) {
			return null;
		}

		const number = Number(value);

		if (!isNumber(number)) {
			return null;
		}

		return number;
	}
}
