/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

import { transformSecondsToMinutes } from 'app/utils/helpers/helpers';

@Pipe({
	name: 'toMinutes',
	standalone: false,
})
export class ToMinutesPipe implements PipeTransform {
	public transform(seconds: number, numberOfDecimalPlaces = 0): number {
		return transformSecondsToMinutes(seconds, numberOfDecimalPlaces);
	}
}
