/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Component } from '@angular/core';

@Component({
	selector: 'access-denied',
	templateUrl: './access-denied.component.html',
	styleUrls: ['./access-denied.component.scss'],
	standalone: false,
})
export class AccessDeniedComponent {}
