/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Component } from '@angular/core';

@Component({
	selector: 'error-page',
	templateUrl: './error-page.component.html',
	styleUrls: ['./error-page.component.scss'],
	standalone: false,
})
export class ErrorPageComponent {}
