/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'isEmpty',
	standalone: false,
})
export class IsEmptyPipe implements PipeTransform {
	public transform(value: Array<any> | string): boolean {
		return value && value.length === 0;
	}
}
