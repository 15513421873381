/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';
import { CookingVariableDeviceOption } from '../../enums/device-option.enum';
import { AttributeConfig } from '../../models/attribute-config/attribute-config.model';

@Pipe({
	name: 'attributeConfigHasOption',
	standalone: false,
})
export class AttributeConfigHasOption implements PipeTransform {
	public transform(
		attributeConfig: AttributeConfig,
		deviceOption: CookingVariableDeviceOption,
	): boolean {
		return attributeConfig?.hasOption(deviceOption);
	}
}
