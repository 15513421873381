/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Locale } from 'app/enums/locale.enum';
import { BrowserDetectorService } from 'app/services/browser-detector/browser-detector.service';
import { LocaleService } from 'app/services/locale/locale.service';
import { PopupDialogService } from 'app/services/popup-dialog/popup-dialog.service';
import { ServiceWorkerService } from 'app/services/service-worker/service-worker.service';
import { VersionCheckService } from './services/version-check/version-check.service';

@Component({
	selector: 'app',
	encapsulation: ViewEncapsulation.None,
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
	standalone: false,
})
export class AppComponent implements OnInit {
	constructor(
		private readonly localeService: LocaleService,
		private readonly browser: BrowserDetectorService,
		private readonly dialog: PopupDialogService,
		private readonly translateService: TranslateService,
		private readonly serviceWorkerService: ServiceWorkerService,
		private readonly versionCheckService: VersionCheckService,
		@Inject(DOCUMENT) private readonly document: Document,
		private readonly router: Router,
		@Inject(PLATFORM_ID) private readonly platformId: object,
	) {}

	public ngOnInit(): void {
		this.localeService.initTranslations();
		this.versionCheckService.init();

		this.localeService.setTranslation(Locale.ENGLISH).subscribe(() => {
			if (this.browser.isIEorEdge(navigator)) {
				this.showMessage();
			}
		});

		this.setupScrollOnNavigation();
		this.registerServiceWorkers();
	}

	private showMessage(): Observable<any> {
		const close$: Subject<any> = new Subject();

		this.dialog
			.setTitle(this.translateService.instant('warning.iedge.title'))
			.setContent(this.translateService.instant('warning.iedge.description'), true)
			.setCancelButton({ label: this.translateService.instant('button.ok') })
			.open()
			.afterClosed()
			.subscribe((response) => {
				close$.next(response);
			});

		return close$;
	}

	private registerServiceWorkers(): void {
		this.serviceWorkerService.registerServiceWorker('recipe-unlocker.sw.js');
	}

	private setupScrollOnNavigation(): void {
		this.router.events
			.pipe(filter((event: any) => event instanceof NavigationEnd))
			.subscribe(() => {
				this.scrollToTop();
			});
	}

	private scrollToTop(): void {
		if (isPlatformBrowser(this.platformId)) {
			this.document.scrollingElement.scrollTop = 0;
		}
	}
}
