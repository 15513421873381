/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { HasOne, ModelConfig } from 'ngx-hal';
import { HalDatastoreModel } from '../services/datastore/models/hal-datastore-model.model';
import { Brand } from './brand.model';
import { Country } from './country.hal.model';
import { Device } from './device.hal.model';

@ModelConfig({
	type: 'ProductBrand',
})
export class ProductBrand extends HalDatastoreModel {
	@HasOne({
		propertyClass: Brand,
	})
	public brand: Brand;

	@HasOne({
		propertyClass: 'Device',
	})
	public device: Device;

	@HasOne({
		propertyClass: Country,
	})
	public country: Country;
}
