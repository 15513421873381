/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

import { groupAsArrayByHelper } from 'app/utils/helpers/helpers';

@Pipe({
	name: 'groupAsArrayBy',
	standalone: false,
})
export class GroupAsArrayByPipe implements PipeTransform {
	transform<T>(items: Array<T> = [], groupBy = ''): Array<Array<T>> {
		return groupAsArrayByHelper(items, groupBy);
	}
}
