/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

import { Observable } from 'rxjs';

import { Locale } from 'app/enums/locale.enum';
import { LocaleService } from 'app/services/locale/locale.service';

@Pipe({
	name: 'translateToLocale',
	standalone: false,
})
export class TranslateToLocalePipe implements PipeTransform {
	public constructor(private readonly localeService: LocaleService) {}

	public transform(key: string, locale: Locale, defaultLocale?: Locale): Observable<string> {
		return this.localeService.getTranslationForLocale(key, locale, defaultLocale);
	}
}
