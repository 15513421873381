/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export enum ScreenName {
	AI_TRANSLATION_LIST = 'aiTranslationList',
	ANNOUNCEMENT_LIST = 'announcementList',
	CAMPAIGN_LIST = 'campaignList',
	COLLECTION_LIST = 'collectionList',
	COMMENT_LIST = 'commentList',
	CONSUMER_LIST = 'consumerList',
	COUNTRY_LIST = 'countryList',
	CUSTOM_TABLE_LIST = 'customTableList',
	EASY_RECIPE_BOOK = 'easyRecipeBook',
	HIGHLIGHT_LIST = 'highlightList',
	INGREDIENT_LIST = 'ingredientList',
	ONBOARDING_ANNOUNCEMENT_LIST = 'onboardingAnnouncementList',
	PLAN_LIST = 'planList',
	PRODUCT_LIST = 'productList',
	PRODUCT_RECIPE_BOOK_LIST = 'productRecipeBookList',
	PROMO_CODE_LIST = 'promoCodeList',
	PUSH_MESSAGE_LIST = 'pushMessageList',
	RECIPE_BOOK_CAROUSEL_LIST = 'recipeBookCarouselList',
	RECIPE_CAROUSEL_LIST = 'recipeCarouselList',
	RECIPE_LIST = 'recipeList',
	REPORTED_CONTENT_LIST = 'reportedContentList',
	SHOP_ANNOUNCEMENT_LIST = 'shopAnnouncementList',
	TIP_LIST = 'tipList',
	USERS_LIST = 'usersList',
}
