/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute as FormAttribute, HasMany as FormHasMany } from 'ngx-form-object';
import { Attribute, HasMany, ModelConfig, ModelEndpoints } from 'ngx-hal';
import { AccessoryType } from '../enums/accessory-type.enum';
import { compareArrays } from '../utils/helpers/compare-arrays/compare-arrays';
import { AccessoryKitAccessory } from './accessory-kit-accessory.hal.model';
import { Device } from './device.hal.model';
import { Product } from './product.model';

@ModelConfig({
	type: 'Accessory',
})
export class Accessory extends Product {
	public static modelType = 'Accessory';
	public displayNameTranslationKey = 'Accessory';
	public readonly isAccessory = true;
	public readonly isDevice = false;
	public readonly isConnectable = false;

	@Attribute()
	@FormAttribute()
	public accessoryType: AccessoryType;

	@HasMany({
		itemsType: 'Device',
	})
	@FormHasMany({
		isChanged: (initial: Array<Device>, current: Array<Device>) =>
			!compareArrays(initial, current, {
				ignoreOrder: true,
				propertyFn: (item: Device) => item.id,
			}),
	})
	public devices: Array<Device>;

	@HasMany({
		itemsType: 'AccessoryKitAccessory',
		externalName: 'accessories',
	})
	public accessoryKitAccessories: Array<AccessoryKitAccessory>;

	@FormHasMany({
		isChanged: (initial: Array<Accessory>, current: Array<Accessory>) =>
			!compareArrays(initial, current, {
				ignoreOrder: true,
				propertyFn: (item: Accessory) => item.id,
			}),
	})
	public get accessories(): Array<Accessory> {
		if (this.accessoryType === AccessoryType.STANDALONE) {
			return [];
		}

		return this.accessoryKitAccessories?.map(
			(accessoryKitAccessory) => accessoryKitAccessory.accessory,
		);
	}

	@HasMany({
		itemsType: 'AccessoryKitAccessory',
		externalName: 'accessoryKits',
	})
	public accessoryAccessoryKits: Array<AccessoryKitAccessory>;

	@FormHasMany({
		isChanged: (initial: Array<Accessory>, current: Array<Accessory>) =>
			!compareArrays(initial, current, {
				ignoreOrder: true,
				propertyFn: (item: Accessory) => item.id,
			}),
	})
	public get accessoryKits(): Array<Accessory> {
		if (this.accessoryType === AccessoryType.KIT) {
			return [];
		}

		return this.accessoryAccessoryKits?.map(
			(accessoryKitAccessory) => accessoryKitAccessory.accessoryKit,
		);
	}

	public get modelEndpoints(): ModelEndpoints {
		return {
			collectionEndpoint: this.datastore.rootApi.accessoryCollectionEndpoint,
			singleResourceEndpoint: this.datastore.rootApi.singleAccessoryEndpoint,
		};
	}

	public get displayName(): string {
		return this.name;
	}
}
