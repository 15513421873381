/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export enum AccessoryType {
	CONSUMABLE = 'CONSUMABLE',
	KIT = 'KIT',
	STANDALONE = 'STANDALONE',
}

export interface IAccessoryTypeData {
	translationKey: string;
}

export const accessoryTypeData: Record<AccessoryType, IAccessoryTypeData> = {
	[AccessoryType.CONSUMABLE]: {
		translationKey: 'accessoryType.consumable',
	},
	[AccessoryType.KIT]: {
		translationKey: 'accessoryType.kit',
	},
	[AccessoryType.STANDALONE]: {
		translationKey: 'accessoryType.standalone',
	},
};
