/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SsoProvider } from '../../../enums/sso-provider.enum';

@Component({
	selector: 'sso-landing-page-button',
	templateUrl: './sso-landing-page-button.component.html',
	styleUrls: ['./sso-landing-page-button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false,
})
export class SsoLandingPageButtonComponent {
	@Input() public ssoProvider: SsoProvider;
	@Input() public iconSrc: string;
	@Output() public ssoProviderSelect: EventEmitter<SsoProvider> = new EventEmitter();

	public onButtonClick() {
		this.ssoProviderSelect.emit(this.ssoProvider);
	}
}
