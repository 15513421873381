/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

import { LanguageService } from 'app/services/language/language.service';
import { Language } from 'app/enums/language.enum';

@Pipe({
	name: 'getLanguageFromId',
	standalone: false,
})
export class GetLanguageFromIdPipe implements PipeTransform {
	public constructor(private readonly languageService: LanguageService) {}

	public transform(language: Language): string {
		if (!language) {
			return null;
		}
		return this.languageService.getLanguageLabelForId(language);
	}
}
