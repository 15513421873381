/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SSO_PROVIDERS_DATA_TOKEN, SsoProvider } from '../../enums/sso-provider.enum';
import { AuthService } from '../../services/auth/auth.service';

@Component({
	selector: 'sso-landing-page',
	templateUrl: './sso-landing-page.component.html',
	styleUrls: ['./sso-landing-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false,
})
export class SsoLandingPageComponent {
	private readonly authService = inject(AuthService);
	protected readonly ssoProvidersData = inject(SSO_PROVIDERS_DATA_TOKEN);

	protected availableSsoProviders = this.getAvailableSsoProviders();

	protected onSsoProviderSelect(provider: SsoProvider): void {
		this.goToLogin(provider);
	}

	private goToLogin(provider: SsoProvider): void {
		this.authService.goToLogin(this.authService.redirectUrlAfterLogin, provider);
	}

	private getAvailableSsoProviders(): Array<SsoProvider> {
		const providers: Array<SsoProvider> = Object.keys(this.ssoProvidersData) as Array<SsoProvider>;
		return providers.filter((provider) => this.ssoProvidersData[provider].available);
	}
}
