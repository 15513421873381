/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

import { unescapeHtml } from '../../utils/helpers/helpers';

/**
 * @deprecated use UnescapeHtmlStandalonePipe
 */
@Pipe({
	name: 'unescapeHtml',
	standalone: false,
})
export class UnescapeHtmlPipe implements PipeTransform {
	public transform(value: string): string {
		return unescapeHtml(value);
	}
}

@Pipe({
	name: 'unescapeHtml',
	standalone: true,
})
export class UnescapeHtmlStandalonePipe implements PipeTransform {
	public transform(value: string): string {
		return unescapeHtml(value);
	}
}
