/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

import { IDialogData } from 'app/components/popup-dialog/dialog-options.interface';

@Component({
	selector: 'confirmation-actions',
	templateUrl: 'confirmation-actions.component.html',
	styleUrls: ['confirmation-actions.component.scss'],
	standalone: false,
})
export class ConfirmationActionsComponent implements OnInit {
	@Input() data: IDialogData;
	@Input() saveDisabled = false;
	@Output() cancel: EventEmitter<any> = new EventEmitter();
	@Output() save: EventEmitter<any> = new EventEmitter();
	public saveClicked$: Subject<void> = new Subject();

	public ngOnInit(): void {
		this.saveClicked$.pipe(throttleTime(2000)).subscribe(() => this.save.emit());
	}

	public onCancelClick(): void {
		this.cancel.emit();
	}

	public onSaveClick(): void {
		this.saveClicked$.next();
	}
}
