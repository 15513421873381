/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import {
	DEFAULT_COVER_IMAGE_LIMIT,
	DEFAULT_PRODUCT_IMAGE_LIMIT,
	DEFAULT_STEP_IMAGE_LIMIT,
	IImageLimit,
} from '../constants/image-limit.constant';
import { Feature } from './feature.enum';

export enum TenantName {
	NUTRIU = 'NutriU',
	COFFEE = 'Coffee+',
	XELSIS = 'Xelsis',
}

interface ITenantData {
	displayName: string;
	iconPrefix: string;
	translationKeysPrefix: string;
	features: Array<string>;
	settings: ITenantSettings;
}

export enum TenantSetting {
	COVER_IMAGE_LIMIT = 'COVER_IMAGE_LIMIT',
	RECIPE_COVER_IMAGE_LIMIT = 'RECIPE_COVER_IMAGE_LIMIT',
	STEP_IMAGE_LIMIT = 'STEP_IMAGE_LIMIT',
	PRODUCT_IMAGE_LIMIT = 'PRODUCT_IMAGE_LIMIT',
}

interface ITenantSettings {
	[TenantSetting.COVER_IMAGE_LIMIT]: IImageLimit;
	[TenantSetting.RECIPE_COVER_IMAGE_LIMIT]: IImageLimit;
	[TenantSetting.STEP_IMAGE_LIMIT]: IImageLimit;
	[TenantSetting.PRODUCT_IMAGE_LIMIT]: IImageLimit;
}

export const tenantData: Record<TenantName, ITenantData> = {
	[TenantName.NUTRIU]: {
		displayName: 'NutriU',
		iconPrefix: 'nutriu',
		translationKeysPrefix: 'nutriu',
		features: [
			Feature.ADMIN_UI_USERS,
			Feature.ANNOUNCEMENTS,
			Feature.ARTICLES,
			Feature.CAMPAIGN,
			Feature.COMMENTS,
			Feature.CONSUMERS,
			Feature.CONTENT_PUBLISHING,
			Feature.CONTENT_SCHEDULER,
			Feature.COUNTRIES,
			Feature.DAM,
			Feature.EASY_RECIPE_BOOK,
			Feature.HIGHLIGHTS,
			Feature.INGREDIENTS,
			Feature.ONBOARDING_ANNOUNCEMENT,
			Feature.PROMO_CODES,
			Feature.PERSONAL_MESSAGING_SYSTEM,
			Feature.PRODUCT_RECIPE_LISTS,
			Feature.PRODUCTS,
			Feature.PUSH_NOTIFICATIONS,
			Feature.RECIPE_BOOK_CAROUSELS,
			Feature.RECIPE_BOOKS,
			Feature.RECIPE_CAROUSELS,
			Feature.RECIPES,
			Feature.REPORTED_CONTENT,
			Feature.SHOP_ANNOUNCEMENTS,
			Feature.TRANSLATIONS_AND_CONTENT_ACTIONS,
		],
		settings: {
			[TenantSetting.COVER_IMAGE_LIMIT]: DEFAULT_COVER_IMAGE_LIMIT,
			[TenantSetting.PRODUCT_IMAGE_LIMIT]: DEFAULT_PRODUCT_IMAGE_LIMIT,
			[TenantSetting.RECIPE_COVER_IMAGE_LIMIT]: DEFAULT_COVER_IMAGE_LIMIT,
			[TenantSetting.STEP_IMAGE_LIMIT]: DEFAULT_STEP_IMAGE_LIMIT,
		},
	},
	[TenantName.COFFEE]: {
		displayName: 'Coffee+',
		iconPrefix: 'coffee',
		translationKeysPrefix: 'coffee',
		features: [
			Feature.ADMIN_UI_USERS,
			Feature.ANNOUNCEMENTS,
			Feature.ARTICLES,
			Feature.CAMPAIGN,
			Feature.COMMENTS,
			Feature.CONSUMERS,
			Feature.CONTENT_PUBLISHING,
			Feature.CONTENT_SCHEDULER,
			Feature.COUNTRIES,
			Feature.DAM,
			Feature.EASY_RECIPE_BOOK,
			Feature.HIGHLIGHTS,
			Feature.INGREDIENTS,
			Feature.ONBOARDING_ANNOUNCEMENT,
			Feature.PROMO_CODES,
			Feature.PERSONAL_MESSAGING_SYSTEM,
			Feature.PRODUCT_RECIPE_LISTS,
			Feature.PRODUCTS,
			Feature.PUSH_NOTIFICATIONS,
			Feature.RECIPE_BOOK_CAROUSELS,
			Feature.RECIPE_BOOKS,
			Feature.RECIPE_CAROUSELS,
			Feature.RECIPES,
			Feature.REPORTED_CONTENT,
			Feature.SHOP_ANNOUNCEMENTS,
			Feature.TRANSLATIONS_AND_CONTENT_ACTIONS,
		],
		settings: {
			[TenantSetting.COVER_IMAGE_LIMIT]: DEFAULT_COVER_IMAGE_LIMIT,
			[TenantSetting.PRODUCT_IMAGE_LIMIT]: DEFAULT_PRODUCT_IMAGE_LIMIT,
			[TenantSetting.RECIPE_COVER_IMAGE_LIMIT]: DEFAULT_COVER_IMAGE_LIMIT,
			[TenantSetting.STEP_IMAGE_LIMIT]: DEFAULT_STEP_IMAGE_LIMIT,
		},
	},
	[TenantName.XELSIS]: {
		displayName: 'Xelsis',
		iconPrefix: 'xelsis',
		translationKeysPrefix: 'xelsis',
		features: [
			Feature.ADMIN_UI_USERS,
			Feature.CONSUMERS,
			Feature.CONTENT_PUBLISHING,
			Feature.CONTENT_SCHEDULER,
			Feature.COUNTRIES,
			Feature.CUSTOM_TABLES,
			Feature.DAM,
			Feature.INGREDIENTS,
			Feature.PERSONAL_MESSAGING_SYSTEM,
			Feature.PRODUCTS,
			Feature.RECIPE_BOOKS,
			Feature.RECIPES,
		],
		settings: {
			[TenantSetting.COVER_IMAGE_LIMIT]: DEFAULT_COVER_IMAGE_LIMIT,
			[TenantSetting.PRODUCT_IMAGE_LIMIT]: DEFAULT_PRODUCT_IMAGE_LIMIT,
			[TenantSetting.RECIPE_COVER_IMAGE_LIMIT]: {
				ignoreOrientation: false,
				min: { width: 800, height: 531 },
				max: { width: 800, height: 531 },
				ratio: { ratioX: 800, ratioY: 531 },
			},
			[TenantSetting.STEP_IMAGE_LIMIT]: DEFAULT_STEP_IMAGE_LIMIT,
		},
	},
};
