/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

import { escapeHtml } from '../../utils/helpers/helpers';

@Pipe({
	name: 'escapeHtml',
	standalone: false,
})
export class EscapeHtmlPipe implements PipeTransform {
	public transform(value = ''): string {
		return escapeHtml(value);
	}
}
