/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, ModelConfig, ModelEndpoints } from 'ngx-hal';
import { HalDatastoreModel } from '../services/datastore/models/hal-datastore-model.model';

@ModelConfig({
	type: 'Brand',
})
export class Brand extends HalDatastoreModel {
	@Attribute()
	public name: string;

	public get modelEndpoints(): ModelEndpoints {
		return {
			collectionEndpoint: this.datastore.rootApi.brandsCollectionEndpoint,
		};
	}
}
