/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'first',
	standalone: false,
})
export class FirstPipe implements PipeTransform {
	public transform(array: Array<any>): any {
		return array && array.length ? array[0] : null;
	}
}
